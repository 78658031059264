@import url("https://fonts.googleapis.com/css2?family=Fasthand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Delicious+Handrawn&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");

.react-datepicker__input-container {
  position: initial !important;
}

.widgets {
  -webkit-user-select: none;
  /* Disable text selection in WebKit browsers */
  -moz-user-select: none;
  user-select: none;
}

.radioButton {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 12px;
  border: 1px solid #007bff;
  border-radius: 50px;
}

.signatureCanvas {
  border: 2px solid #888;
  background-color: rgb(255, 255, 255);
  width: 460px;
  height: 184px;
}

.intialSignatureCanvas {
  border: 2px solid #888;
  background-color: rgb(255, 255, 255);
  width: 150px;
  height: 150px;
}

.intialSignature {
  border: 2px solid #888;
  background-color: rgb(255, 255, 255);
  width: 183px;
  height: 183px;
}

.penContainerDefault {
  width: 460px;
}

.icon {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  z-index: 2;
  background: white;
}

.ScrollbarsCustom-TrackY {
  width: 4px !important;
}
.ScrollbarsCustom-TrackX {
  height: 4px !important;
}

.signYourselfBlock {
  padding: 0px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.sendMail {
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.18);
  padding: 3px 30px;
  color: white;
  font-weight: 500 !important;
  font-size: 14px !important;
  border: none;
  margin-left: 10px;
}

.user {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.disabled {
  opacity: 0.5;
  /* Example: reduce opacity to visually indicate disabled state */
  pointer-events: none;
  /* Prevents mouse clicks and other events */
  /* background-color: #888; */
}

.signTab {
  cursor: pointer !important;
  font-weight: 400 !important;
  margin: 7px !important;
  font-size: 15px !important;
}

.signerComponent {
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  /* width: 180px; */
  background-color: white;
}

.uploadImgLogo {
  font-size: 50px;
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 130px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 5px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 30 !important;
}

.DropdownMenuContent[data-side="top"],
.DropdownMenuSubContent[data-side="top"] {
  animation-name: slideDownAndFade;
}

.DropdownMenuContent[data-side="right"],
.DropdownMenuSubContent[data-side="right"] {
  animation-name: slideLeftAndFade;
}

.DropdownMenuContent[data-side="bottom"],
.DropdownMenuSubContent[data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.DropdownMenuContent[data-side="left"],
.DropdownMenuSubContent[data-side="left"] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem {
  font-size: 13px;
  line-height: 1;
  color: black;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 20px;
  user-select: none;
  outline: none;
  border: none;
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 70px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  border: none;
  background-color: #887abf;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.selectEmail {
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  border: none;
  background-color: #887abf;
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

option {
  overflow-y: scroll;
}

.SelectTrigger:hover {
  border: none;
  outline: none;
}

.selectEmail:hover {
  border: none;
  outline: none;
}

.SelectTrigger:focus {
  /* box-shadow: 0 0 0 2px black; */
  border: none;
  outline: none;
}

.selectEmail:focus {
  /* box-shadow: 0 0 0 2px black; */
  border: none;
  outline: none;
}

.SelectTrigger[data-placeholder] {
  color: black;
}

.SelectIcon {
  color: Var(--violet-11);
}

.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet-11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  z-index: 10 !important;
}

.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--violet-9);
  color: var(--violet-1);
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}

.dropDownStyle {
  width: auto;
}

.autoSignScroll {
  overflow-y: auto;
}

.autoSignScroll::-webkit-scrollbar {
  display: none;
}

.validateInputText {
  width: 76%;
}

.radioGroupInput {
  width: 100%;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media screen and (max-width: 766px) {
  .validateInputText {
    width: 93%;
  }

  .dropdown-menu {
    min-width: 0rem !important;
  }

  #navbar {
    overflow: hidden;
    z-index: 49;
  }

  /* Navbar links */
  #navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  #navbar a.active {
    background-color: #4caf50;
    color: white;
  }

  /* Page content */
  .content {
    padding: 16px;
  }

  /* The sticky class is added to the navbar
  with JS when it reaches its scroll position */
  .stickyHead {
    top: 50px;
    left: 0.9rem;
  }

  .stickySignerHead {
    position: fixed;
  }

  /* Add some top padding to the page content
  to prevent sudden quick movement (as the
  navigation bar gets a new position at the top of the
  page (position:fixed and top:0) */
  .stickyHead + .content {
    padding-top: 60px;
  }
}

@media screen and (max-width: 487px) and (min-width: 351px) {
  .signatureCanvas {
    width: 300px;
    height: 120px;
  }

  .penContainerDefault {
    width: 300px;
  }

  .uploadImgLogo {
    font-size: 30px;
  }

  .signTab {
    font-weight: 500 !important;
    font-size: 12px !important;
  }
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 350px) and (min-width: 311px) {
  .signatureCanvas {
    width: 280px;
    height: 112px;
  }

  .penContainerDefault {
    width: 280px;
  }

  .signTab {
    font-weight: 500 !important;

    font-size: 10px !important;
  }

  .uploadImgLogo {
    font-size: 25px;
  }
}

@media screen and (max-width: 310px) {
  .signatureCanvas {
    width: 230px;
    height: 92px;
  }

  .penContainerDefault {
    width: 230px;
  }

  .signTab {
    font-weight: 500 !important;
    font-size: 8px !important;
  }

  .uploadImgLogo {
    font-size: 20px;
  }
}
